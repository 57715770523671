<template>
  <div class="container">
    <img src="/imgs/warning.svg" alt="Warning error icon" />
    <div class="title flex-initial text-center my-32 pb-16 text-30 text-blue-700">
      {{ $t('MAINTENANCE.TITLE') }}
    </div>
    <div class="title flex-initial text-center my-32 pb-16 text-20 text-blue-500">
      {{ $t('MAINTENANCE.SUBTITLE') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
}
</script>

<style lang="scss" scoped>
.container {
  display: block;
  margin-left: auto;
}

img {
  display: block;
  width: 5rem;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
}
</style>
