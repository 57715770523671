<template>
  <div>
    <p>You are not authorized</p>
    <router-link to="login">login</router-link>
  </div>
</template>

<script>
export default {
  name: 'Unauthorized',
}
</script>
